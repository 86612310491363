<template>
  <v-card class="pa-2">
    <v-card-title class="justify-center">Excluir Mensagem </v-card-title>
    <v-card-text>
      <chat-image v-if="true" :imgsrc="messageToDelete.image_base64" :thumbsrc="messageToDelete.image_base64"
        :imgWidth="500"></chat-image>
      <v-row>

        <v-col class="d-flex" cols="12" sm="12" v-if="messageToDelete.content">
          <v-textarea auto-grow readonly label="Mensagem" :background-color="$vuetify.theme.isDark ? '#272727' : 'white' " outlined id="textareamessage"
            hide-details="true" rows=1 v-model="messageToDelete.content">
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-checkbox v-model="everyone" readonly disabled
            label="Excluir para todos"></v-checkbox>
        </v-col>
        <!-- <v-col cols="12">
          <v-alert
          density="compact"
          type="warning"
          icon="mdi-alert-circle"
          >Sempre excluirá a mensagem para todos os usuários</v-alert>
        </v-col> -->
        
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn text @click="showDialogDeleteMessage({ show: false, message: '' })">Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="deleteMessage()">Excluir</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Image from "./Image.vue";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { mapActions } from 'vuex/dist/vuex.common.js';
export default {
  props: [
    'contacts',
    'deleteMessage',
    'showDialog',
    'showDialogDeleteMessage',
    'image'
  ],
  components: {
    "chat-image": Image,
  },
  computed: {
    ...mapFields('message', [
      'instance_id',
      'textEdit',
    ]),
    ...mapGetters("instances", { instances: "instancesChat" }),
    ...mapState('message', ['forward_mode', 'messageToDelete']),
  },
  data: () => ({
    selected_contacts: [],
    searchInput: '',
    everyone:true
  }),
  methods: {
    ...mapActions('message', ['setTextEdit']),

    customFilter(item) {
      return item
    },
    getText(item) {
      if (item.name) {
        if (item.name.length == 1) {
          return item.name
        }

        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
  },
  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        contacts: this.selected_contacts
      }
      this.getContacts(payload)
    },
  },
}
</script>

<style>
.contato-autocomplete {
  margin: 10px !important;
}
</style>