<template>
    <div>
        <v-tabs v-model="tab" class="elevation-10">
            <v-tab :disabled="mode == 'EDIT'">Descrição</v-tab>
            <v-tab :disabled="mode == 'EDIT'">Anotados</v-tab>
            <v-tab :disabled="mode == 'EDIT'">Dados Do Cliente</v-tab>
            <v-spacer></v-spacer>

            <v-btn class="mr-2" v-if="tab != 2" icon @click="toogleEditMode">
                <v-icon color="black">{{ mode == 'VIEW' ? 'mdi-pencil' : 'mdi-pencil-off' }}</v-icon>
            </v-btn>
        </v-tabs>
        <v-tabs-items v-model="tab" class="elevation-10">
            <v-tab-item>
                <v-form ref="formDescription">
                    <v-card class="contact-card-description">
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm12 md12>
                                        <v-avatar v-if="avatar" size="40" class="avatar-header">
                                            <v-img v-if="avatar" :src="avatar"></v-img>
                                        </v-avatar>
                                        <v-card-title>
                                            {{ contact_name ? contact_name : contact_id }}
                                            <v-divider></v-divider>
                                            {{ contact_id }}
                                        </v-card-title>
                                    </v-flex>

                                    <v-flex xs12 sm12 md12 v-if="checkPermissionCreateAnnotatedManual()">
                                        <v-textarea auto-grow @click:append="
                                            mode == 'VIEW'
                                                ? (mode = 'EDIT')
                                                : saveChange({
                                                    screen: screen,
                                                })
                                            " label="Descrição" :background-color="$vuetify.theme.isDark ? '#272727' : 'white' " outlined id="textareadesc"
                                            :readonly="mode == 'VIEW'" hide-details="true" v-model="contact_description"
                                            :value="contact_description"
                                            v-on:keyup.enter="checkEnter('contact_description')">
                                        </v-textarea>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 v-else>
                                        <v-textarea auto-grow label="Descrição" :background-color="$vuetify.theme.isDark ? '#272727' : 'white' " outlined
                                            id="textareadesc" :readonly="mode == 'VIEW'" hide-details="true"
                                            v-model="contact_description" :value="contact_description"
                                            v-on:keyup.enter="checkEnter('contact_description')">
                                        </v-textarea>
                                    </v-flex>

                                    <v-flex cols="12" sm="12" v-if="screen == 'chat'">
                                        <v-chip active-class="" v-for="chip in chipsList" class="ma-2" :key="chip.id"
                                            @click="changeStatus(chip)" :color="getColorChip(chip)"
                                            :text-color="chip.status == 1 ? 'white' : 'black'">
                                            {{ chip.label }}
                                        </v-chip>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-checkbox v-if="contact_id.length > 13" required label="Enviar Comprovante"
                                            v-model="sendReceipt" outlined :readonly="mode == 'VIEW'"
                                            :disabled="mode == 'VIEW'"></v-checkbox>
                                    </v-flex>




                                </v-layout>
                            </v-container>
                        </v-card-text>

                        <v-card-actions v-if="this.mode == 'EDIT' && this.screen == 'chat'">
                            <v-col>
                                <v-btn color="red" @click="
                                    showDialogContactDescription({
                                        show: false,
                                        annotated: '',
                                        contact_description: '',
                                        chips_contact: '',
                                    })
                                    ">Cancelar</v-btn>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn color="primary" @click="
                                    saveDescription({
                                        screen: screen,
                                    })
                                    ">Salvar</v-btn>
                            </v-col>

                        </v-card-actions>

                    </v-card>
                </v-form>
            </v-tab-item>
            <v-tab-item>
                <v-form ref="formAnnotated">
                    <v-card class="contact-card-description">
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm3 md3 v-if="checkPermissionCreateAnnotatedManual()">
                                        <v-text-field required label="Alterar Anotados" v-model="changed_value"
                                            :readonly="mode == 'VIEW'" outlined
                                            :rules="[rules.required, rules.decimal(2)]"
                                            :disabled="this.openedChat.block_annotated"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3 md3 v-else>
                                        <v-text-field required label="Alterar Anotados" v-model="changed_value"
                                            :readonly="mode == 'VIEW'" :error-messages="errors.collect('changed_value')"
                                            v-validate="'decimal:2'" data-vv-name="changed_value" outlined
                                            :disabled="this.openedChat.block_annotated"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3 md3>
                                        <v-text-field required label="Pagamento Pendente *" v-model="pending_payment"
                                            :readonly="true" outlined></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3 md3>
                                        <v-text-field required label="Semana Atual *" v-model="current_week"
                                            :readonly="true" outlined></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3 md3>
                                        <v-text-field required label="Adiantamento de Rakeback *" v-model="weekly_advance"
                                            :readonly="true" outlined></v-text-field>
                                    </v-flex>


                                    <v-flex xs12 sm4 md4 v-if="mode != 'VIEW'">
                                        <v-select required label="Tipo de Anotado *" v-model="origin"
                                            :items="annotated_types" :readonly="mode == 'VIEW'"
                                            :rules="[rules.requiredAllowZero]" item-text="description" item-value="id"
                                            outlined></v-select>
                                    </v-flex>
                                    <v-flex xs12 sm8 md8 v-if="mode != 'VIEW'">
                                        <v-text-field required label="Motivo da Alteração Manual *"
                                            v-model="description" :readonly="mode == 'VIEW'" :rules="[rules.required]"
                                            outlined></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 v-if="showAddTo && changed_value > 0">
                                        <v-select :items="types_add_to" label="Adicionar a(o)" outlined v-model="add_to"
                                            item-text="description" item-value="id"></v-select>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 v-if="showAddTo && changed_value != '' &&
                                        !(parseFloat(changed_value) >= 0)">
                                        <v-select :items="types_add_to" label="Abater do(a)" outlined
                                            v-model="discount_from" item-text="description" item-value="id"></v-select>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 mb-4 v-if="checkPermissionCreateAnnotatedManual()">
                                        <v-textarea auto-grow label="Anotados - Observação" :background-color="$vuetify.theme.isDark ? '#272727' : 'white' "
                                            outlined id="textareadesc" :readonly="mode == 'VIEW'" hide-details="true"
                                            v-model="observation" :value="observation"
                                            v-on:keyup.enter="checkEnter('annotated')">
                                        </v-textarea>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 mb-4 v-else>
                                        <v-textarea auto-grow label="Anotados - Observação" :background-color="$vuetify.theme.isDark ? '#272727' : 'white' "
                                            outlined id="textareadesc" :readonly="mode == 'VIEW'" hide-details="true"
                                            v-model="observation" :value="observation"
                                            v-on:keyup.enter="checkEnter('annotated')">
                                        </v-textarea>
                                    </v-flex>

                                    <v-flex xs12 sm11 md11>
                                        <v-text-field required label="Link de Pagamento" v-model="urlCompleta"
                                            :readonly="true" outlined></v-text-field>
                                    </v-flex>

                                    <v-flex xs6 sm1 md1 my-3 v-if="!hash_contact_id">
                                        <v-icon large color="black" @click="copyText">mdi-content-copy</v-icon>
                                    </v-flex>


                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions v-if="this.mode == 'EDIT' && this.screen == 'chat'">
                            <v-col>
                                <v-btn color="red" @click="
                                    showDialogContactDescription({
                                        show: false,
                                        annotated: '',
                                        contact_description: '',
                                        chips_contact: '',
                                    })
                                    ">Cancelar</v-btn>
                            </v-col>
                            <v-col class="text-center" v-if="showButtonIgnoreExists">
                                <v-btn color="orange" @click="
                                    saveAnnotated({
                                        screen: screen,
                                        check_exists: false,
                                    })
                                    ">Forçar Criação </v-btn>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn color="primary" @click="
                                    saveAnnotated({
                                        screen: screen,
                                    })
                                    ">Salvar</v-btn>
                            </v-col>

                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-tab-item>

            <v-tab-item v-if="showClientDetails">

                <v-card class="contact-card-description pa-2">
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row>
                                <v-col>
                                    <h3><strong>Dados Gerais</strong></h3>
                                </v-col>
                            </v-row>
                            <v-divider class="my-4">
                            </v-divider>
                            <v-row class="pb-0">
                                <v-col xs="12" sm="5" md="5" v-if="client_details.displayName" class="pb-0">

                                    <v-text-field required label="Nome do Cliente" v-model='client_details.displayName'
                                        :readonly="true" outlined></v-text-field>
                                </v-col>
                                <v-col xs="12" sm="2" md="2" v-if="client_details.paymentType" class="pb-0">

                                    <v-text-field required label="Tipo de Pagamento"
                                        v-model='client_details.paymentType' :readonly="true" outlined></v-text-field>
                                </v-col>
                                <v-col xs="12" sm="3" md="3" v-if="client_details.limitType" class="pb-0">

                                    <v-text-field required label="Tipo de Limite" v-model='limitTypeLabel'
                                        :readonly="true" outlined></v-text-field>
                                </v-col>

                                <v-col xs="12" sm="2" md="2" v-if="client_details.limit || client_details.limit == 0"
                                    class="pb-0">
                                    <v-text-field prepend-icon="mdi-currency-brl" required label="Limite" v-model='client_details.limit'
                                        :readonly="true" outlined ></v-text-field>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3><strong>Agentes</strong></h3>
                                </v-col>
                            </v-row>
                            <v-divider class="my-4">
                            </v-divider>

                            <v-row class="mb-4">
                                <v-data-table hide-default-footer :headers="headersSlots" :items="client_details.agents"
                                    :items-per-page="5" class="elevation-1">
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.nickname }}</td>
                                            <td>{{ item.player_id }}</td>
                                            <td>{{ item.club.appId }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-row>


                            <v-divider class="my-4">
                            </v-divider>

                            <v-row>
                                <v-col>
                                    <h3><strong>Clubes</strong></h3>
                                </v-col>
                            </v-row>

                            <v-divider class="my-4">
                            </v-divider>


                            <v-row>
                                <v-container>
                                    <v-expansion-panels>
                                        <v-expansion-panel v-for="(deal, index) in client_details.deals" :key="deal.id" class="elevation-3">
                                            <v-expansion-panel-header>
                                                <strong>Clube {{ deal.slot ? getClubName(deal.slot) : index + 1
                                                    }}</strong>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col xs="12" sm="4" md="4">
                                                        <v-text-field label="Nickname" v-model='deal.nickname'
                                                            :readonly="true" outlined></v-text-field>
                                                    </v-col>
                                                    <v-col xs="12" sm="2" md="2">
                                                        <v-text-field label="Id Clube" v-model='deal.slot'
                                                            :readonly="true" outlined></v-text-field>
                                                    </v-col>
                                                    <v-col xs="12" sm="1" md="1">
                                                        <v-text-field label="Liga" v-model='deal.league'
                                                            :readonly="true" outlined></v-text-field>
                                                    </v-col>
                                                    <v-col xs="12" sm="2" md="2">
                                                        <v-text-field label="Plataforma" v-model='deal.platform'
                                                            :readonly="true" outlined></v-text-field>
                                                    </v-col>

                                                    <v-col xs="12" sm="3" md="3">
                                                        <v-text-field label="Deal Type" v-model='deal.dealType'
                                                            :readonly="true" outlined></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <h3><strong>Deals</strong></h3>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-4">
                                                </v-divider>


                                                <v-data-table :headers="headers" :items="deal.RakeBack" hide-default-footer
                                                    :items-per-page="5" class="elevation-2">
                                                    <template v-slot:item="{ item }">
                                                        <tr>
                                                            <td>R$ {{ item.initialValue }}</td>
                                                            <td>R$ {{ item.finalValue }}</td>
                                                            <td>{{ item.percent }} %</td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-container>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs-items>
    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import rules from "../../../../util/rules";
export default {
    computed: {
        ...mapFields('chat', [
            'contact_annotated.changed_value',
            'contact_annotated.description',
            'contact_annotated.origin',
            'contact_annotated.current_week',
            'contact_annotated.discount_from',
            'contact_annotated.pending_payment',
            'contact_annotated.observation',
            'contact_annotated.new_annotated',
            'contact_annotated.add_to',
            'client_details',
            'old_annotated',
            'contact_description',
            'chips_contact',
            'sendReceipt',
        ]),
        ...mapState('rakeback', ['weekly_advance']),

        ...mapState('login', ['userData']),
        ...mapState("clubs", ["clubs"]),
        ...mapState('chat', ['possible_origins', 'showButtonIgnoreExists', 'showClientDetails']),
        chipsList() {
            return this.mode == 'VIEW'
                ? this.chips_contact.filter((c) => c.status == 1)
                : this.chips_contact
        },
        showAddTo() {
            let roles = [2, 5, 80, 81, 97, 98, 99]
            return roles.indexOf(this.userData.role) != -1
        },
        urlCompleta() {
            if (this.hash_contact_id) {
                return this.url_base_link + this.hash_contact_id
            } else {
                return ''
            }
        },

        limitTypeLabel() {
            const TYPES = [
                { value: 0, label: 'Anotados' },
                { value: 1, label: 'Anotados + Adiantamento de Rake' }
            ];
            const type = TYPES.find((t) => t.value === this.client_details.limitType);
            return type ? type.label : 'Desconhecido';
        }
    },
    props: [
        'avatar',
        'contact_id',
        'contact_name',
        'hash_contact_id',
        'generate_hash',
        'description',
        'screen',
        'start_mode',
        'showContactDescription',
        'openedChat',
    ],
    created() {
        this.getClubs({ resetPage: false, noPages: true, onlyActive: true });
        this.mode = this.start_mode
        if (!this.hash_contact_id) {
            this.generate(this.contact_id)
        }
    },
    data: () => ({
        annotated_types: [
            { id: 4, description: 'Correção de Fechamento' },
            { id: 5, description: 'Correção de Saldo Incorreto' },
            { id: 6, description: 'Correção de Valor Lançado Incorretamente' },
            { id: 7, description: 'Multa Suprema' },
        ],
        mode: '',
        types_add_to: [
            { id: 0, description: 'Semana Atual' },
            { id: 1, description: 'Pagamento Pendente' },
        ],
        url_base_link: 'https://anotados.flutterflow.app/annotatedPage?hash=',
        rules: rules,
        tab: 'description',
        headers: [
            { text: 'Valor Inicial', value: 'initialValue' },
            { text: 'Valor Final', value: 'finalValue' },
            { text: 'Percentual', value: 'percent' },
        ],
        headersSlots: [
            { text: 'ID', value: 'id' },
            { text: 'Nickname', value: 'nickname' },
            { text: 'Id do Agente', value: 'player_id' },
            { text: 'Clube', value: 'club.appId' },
        ],

    }),
    methods: {
        ...mapGetters('login', ['checkProfileAcessWithdrawalLeader']),
        ...mapGetters("profile", ["checkPermissionCreateAnnotatedManual"]),
        ...mapMutations('chat', [
            'showDialogContactDescription',
            'showNotification',
        ]),
        ...mapActions('chat', ['saveContactDescriptionChange', 'saveAnnotatedChange', 'saveDescriptionChange', 'generateHash']),
        ...mapActions("clubs", [
            "getClubs",
        ]),
        saveChange(payload) {
            let valid = this.$refs.formContactDescription.validate()
            if (valid) {
                this.saveContactDescriptionChange(payload)
            }
        },
        saveDescription(payload) {
            let valid = this.$refs.formDescription.validate()
            if (valid) {
                this.saveDescriptionChange(payload)
            }
        },
        saveAnnotated(payload) {
            let valid = this.$refs.formAnnotated.validate()
            console.log("AQUI", payload)
            if (valid) {
                this.saveAnnotatedChange(payload)
            }
        },
        toogleEditMode() {
            this.mode = this.mode == 'VIEW' ? 'EDIT' : 'VIEW'
        },
        getClubName(id) {
            const club = this.clubs.find(club => club.club === id);
            return club ? club.clubLabel : "Desconhecido";
        },
        checkEnter(field) {
            if (!event.ctrlKey && !event.shiftKey) {
                if (this.mode == 'EDIT') {
                    event.preventDefault()
                    this[field] = this[field].substr(0, this[field].length - 1)
                    this.saveContactDescriptionChange({ screen: this.screen })
                }
            } else {
                this[field] = this[field] + '\n'
            }
        },
        copyText() {
            let text = this.urlCompleta
            navigator.clipboard.writeText(text).then(
                () => {
                    let notification = {
                        show: true,
                        message: 'Link copiado para área de transferência',
                        type: 'success',
                    }
                    this.showNotification(notification)
                },
                function (err) {
                    console.error('Async: Could not copy text: ', err)
                    let notification = {
                        show: true,
                        message: 'Erro ao copiar o link',
                        type: 'error',
                    }
                    this.showNotification(notification)
                },
            )
        },
        async generate(contact_id) {
            let hash = await this.generateHash(contact_id)
            this.$emit('updateProp', hash)
        },
        changeStatus(chip) {
            if (this.mode == 'EDIT') {
                if (chip.status == 0) {
                    chip.status = 1
                } else {
                    chip.status = 0
                }
            }
        },
        getColorChip(chip) {
            if (chip.status == 1) {
                if (chip.id == 1) {
                    return 'orange'
                } else if (chip.id == 2) {
                    return 'green'
                } else if (chip.id == 3) {
                    return 'blue'
                } else if (chip.id == 4) {
                    return 'red'
                } else if (chip.id == 5) {
                    return 'purple'
                } else if (chip.id == 9 || chip.id == 8) {
                    return 'yellow'
                } else {
                    return 'black'
                }
            }
        },
    },
    watch: {
        showContactDescription: function () {
            if (this.showContactDescription == false) {
                this.mode = 'VIEW'
            } else {
                if (this.generate_hash) {
                    this.generate(this.contact_id)
                }
            }
        },
    },
}
</script>

<style>
@import './css/contact_description.css';
</style>
